import {
  selectorSrcmApiStoreDoc,
  selectorSrcmApiStoreOrdered,
} from "gatsby-plugin-hfn-profile/state/selectors";
import { fetchSrcmApiStoreIfNeeded } from "gatsby-plugin-hfn-profile/state/actions_srcm";
import { uniq, set, get } from "lodash";
import { useUpperFirst } from "sites-common/utils/unCamelCase";
import ScheduleCard from "./ScheduleCard";
import {
  eventFormFields,
  postprocess,
  eventFormDefaults,
  eventFormvalidations,
} from "../../components/crud-shared/eventFormFields";

const eventsCollection = "approvalevents";

const sEventsId = (state, docId) => {
  let result = {};
  result = selectorSrcmApiStoreDoc(state, eventsCollection, docId);
  set(result, "paid_skus", get(result, ["event_json", "paid_skus"], []));
  return result;
};

const selectorOptionsStatus = (state) => {
  return uniq(
    selectorSrcmApiStoreOrdered(state, eventsCollection).map((r) => r.status)
  ).map((x) => ({ key: x, text: useUpperFirst(x) }));
};

const selectorEventsForStatus = (state, status) =>
  status
    ? selectorSrcmApiStoreOrdered(state, eventsCollection)
        .filter((r) => r.status === status)
        .sort((a, b) => a.start_datetime <= b.start_datetime)
    : [];

const statusDispatcher = () =>
  fetchSrcmApiStoreIfNeeded(eventsCollection, { page: 1, page_size: 500 });

const Config = {
  sessionKey: "events-sk",
  noItemMsg: "No Events Found",
  mainTitle: "Events Approval",

  // different page configurations
  pageOptions: {
    All: {
      optionsSelector: () => [{ key: "All", text: "My Events" }],
      pageSelector: (state) =>
        selectorSrcmApiStoreOrdered(state, eventsCollection),
    },
    Status: {
      optionsSelector: selectorOptionsStatus,
      pageSelector: selectorEventsForStatus,
      pageDispatcher: statusDispatcher,
    },
  },

  collection: eventsCollection,
  primaryKey: "name",
  disableEditPrimaryKey: true,
  autogenPrimaryKey: true,
  displayAsTable: false,
  renderItemCard: ScheduleCard,
  panelForEditItem: true,
  showTableHeader: true,
  formFields: eventFormFields,
  formDefaults: eventFormDefaults,
  formValidator: eventFormvalidations,
  postprocess,

  singleItemSelector: sEventsId,
  singleItemDispatcher: null,

  // newBtnText: "New",
  // enableBulkUpload: true,
  editBtnText: "View / Edit Details",
  editBtnType: "action",
  tableEditButtonProps: { text: "Edit" },
  // tableDeleteButtonProps: {text: "Delete"},

  // colDefns: renderColumns(),
  // excelColDefns: excelColumns(),
  filterProps: {
    placeholder: "Title / Preview",
    searchFunction: (r) => `${r.title} ${r.preview}`,
    filterMenuItems: [
      {
        key: "date",
        text: "Start Date (Recent First)",
        f: (a, b) => {
          if (a.start_datetime.toLowerCase() < b.start_datetime.toLowerCase()) {
            return 1;
          }
          if (b.start_datetime.toLowerCase() < a.start_datetime.toLowerCase()) {
            return -1;
          }
          return 0;
        },
      },
      {
        key: "title",
        text: "Name (Ascending)",
        f: (a, b) => {
          if (a.title.toLowerCase() > b.title.toLowerCase()) {
            return 1;
          }
          if (b.title.toLowerCase() > a.title.toLowerCase()) {
            return -1;
          }
          return 0;
        },
      },
      {
        key: "title",
        text: "Name (Decending)",
        f: (a, b) => {
          if (a.title.toLowerCase() < b.title.toLowerCase()) {
            return 1;
          }
          if (b.title.toLowerCase() < a.title.toLowerCase()) {
            return -1;
          }
          return 0;
        },
      },
      {
        key: "group",
        text: "Group (Ascending)",
        f: (a, b) => {
          if (a.category.name.toLowerCase() > b.category.name.toLowerCase()) {
            return 1;
          }
          if (b.category.name.toLowerCase() > a.category.name.toLowerCase()) {
            return -1;
          }
          return 0;
        },
      },
      {
        key: "group",
        text: "Group (Decending)",
        f: (a, b) => {
          if (a.category.name.toLowerCase() < b.category.name.toLowerCase()) {
            return 1;
          }
          if (b.category.name.toLowerCase() < a.category.name.toLowerCase()) {
            return -1;
          }
          return 0;
        },
      },
    ],
  },
};

export default Config;
