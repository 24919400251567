/** @jsx jsx */
import {
  jsx,
  Spinner,
  Flex,
  Box,
  Badge,
  Label,
  Textarea,
  Container,
  Grid,
  Image,
  Text,
} from "theme-ui";
import { useState, useRef } from "react";
import { DefaultButton } from "office-ui-fabric-react";
import { doFormatShortDate } from "@heartfulnessinstitute/react-hfn-forms/dist/formatting";
import { get } from "lodash";
import {
  Dialog,
  DialogType,
  DialogFooter,
} from "office-ui-fabric-react/lib/Dialog";
import { buttonStyles } from "sites-common/utils/fabricStyles";
import PropTypes from "prop-types";
import { useUpperFirst } from "sites-common/utils/unCamelCase";
import useOnClickOutside from "sites-common/hooks/useOnClickOutside";
import AppIcons from "sites-common/utils/icons";
import { appsDialogStyles } from "../../utils/dialogStyles";

function ScheduleCardC({ item, EditItemButton, onSave }) {
  const [updating, setUpdating] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const ref = useRef();
  useOnClickOutside(ref, () => setShowMenu(false), showMenu);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const onApprove = () => {
    setUpdating(true);
    const afterUpdate = () => {
      setUpdating(false);
      // sendConfirmationMail(item, user.email);
    };

    const afterFailure = () => {
      setUpdating(false);
    };

    onSave(item, afterUpdate, afterFailure, {
      api_workflow: "publish",
      use_method: "POST",
    });
  };

  const onReject = () => {
    const newdata = { ...item, audit_log: rejectReason };
    setUpdating(true);
    const afterUpdate = () => {
      setUpdating(false);
      setRejectReason("");
      closeModal();
      // sendConfirmationMail(item, user.email);
    };

    const afterFailure = () => {
      setUpdating(false);
    };

    onSave(newdata, afterUpdate, afterFailure, {
      api_workflow: "reject",
      use_method: "POST",
    });
  };

  const onCancel = () => {
    // logged in user name
    const username =
      JSON.parse(window.localStorage.getItem("pstate")) &&
      JSON.parse(window.localStorage.getItem("pstate")).user.name;
    const newdata = { ...item, audit_log: `cancelled by ${username}` };
    setUpdating(true);
    const afterUpdate = () => {
      setUpdating(false);
      // sendConfirmationMail(item, user.email);
    };

    const afterFailure = () => {
      setUpdating(false);
    };

    onSave(newdata, afterUpdate, afterFailure, {
      api_workflow: "cancel",
      use_method: "POST",
    });
  };

  const handleInputChange = (e) => {
    setRejectReason(e.target.value);
  };

  const EventsMenuItem = () => {
    return (
      <Container ref={ref}>
        <Box
          onBlur={() => setShowMenu(false)}
          onClick={() => {
            setShowMenu(!showMenu);
          }}
          role="button"
          tabIndex="0"
        >
          <Image
            sx={{
              "&: hover": {
                cursor: "pointer",
              },
            }}
            src={!showMenu ? AppIcons.more_dot : AppIcons.cross_delete}
            alt="event more"
            width="20"
          />
        </Box>
        {showMenu && (
          <Box sx={{ position: "relative", zIndex: 9999 }}>
            <Box
              sx={{
                position: "absolute",
                width: "300px",
                right: 0,
                boxShadow: "0px 3px 6px #00000029",
                backgroundColor: "#ffffff",
                transform: "translate(0, 10px)",
                "@media (max-width: 480px)": {
                  width: "auto",
                },
              }}
            >
              <Box
                sx={{
                  flexBasis: ["auto", 202],
                  order: -1,
                  boxShadow: "0px 3px 6px #00000029",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      borderBottom: "1px solid #dddddd",
                      cursor: "pointer",
                      margin: "1px",
                      "&:hover": {
                        background: "#E8F5FE",
                        "& > div, i": {
                          color: "#1DA0F2",
                        },
                      },
                      "& > div": {
                        color: "#333333",
                        fontSize: "16px",
                      },
                      "@media (max-width: 480px)": {
                        "& > div": {
                          fontSize: "14px",
                        },
                      },
                    }}
                  >
                    <Box
                      sx={{
                        px: 3,
                        py: 2,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <EditItemButton
                        sx={{
                          width: "100%",
                          fontSize: "16px",
                          color: "#333333",
                          "@media (max-width: 480px)": {
                            fontSize: "14px",
                          },
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Container>
    );
  };

  return (
    <Container
      sx={{
        color: "#333333",
        letterSpacing: "0.24px",
        boxShadow: "0px 3px 6px #00000029",
        opacity: 1,
        borderRadius: "5px",
        backgroundColor: "#ffffff",
      }}
      p={3}
    >
      <Grid
        columns={[3, "6fr 3fr 1fr"]}
        pb={3}
        sx={{
          "@media (max-width: 600px)": {
            display: "block",
          },
        }}
      >
        <Box
          sx={{
            fontWeight: "medium",
            fontSize: 3,
          }}
        >
          <Text mr={2}>{useUpperFirst(item.title)}</Text>
          <Badge
            sx={{
              bg: "#228B22",
              "@media (max-width: 600px)": {
                ml: 0,
              },
            }}
          >
            {get(item, "category.name")}
          </Badge>
        </Box>
        <Box>
          <div
            sx={{
              textAlign: "end",
              "@media (max-width: 600px)": {
                textAlign: "left",
                mt: 1,
              },
            }}
          >
            {doFormatShortDate(item.start_datetime)} -{" "}
            {doFormatShortDate(item.end_datetime)}
          </div>
        </Box>
        <Box>
          <div sx={{ textAlign: "end" }}>
            <EventsMenuItem />
          </div>
        </Box>
      </Grid>
      <Grid>
        <Box>
          <div>
            <b>Group: </b>
            {item.is_venue_online ? "online" : "offline"}
          </div>
          <div sx={{ mb: 2 }}>
            <b>Status: </b>
            {item.status}
          </div>
          <Flex
            sx={{
              gap: 2,
              "@media (max-width: 600px)": {
                flexDirection: "column",
                justifyContent: "center",
                width: "160px",
              },
            }}
          >
            {updating && <Spinner size={35} />}
            {item.status === "pending" && (
              <DefaultButton
                styles={buttonStyles.blueLight}
                disabled={updating}
                onClick={onApprove}
              >
                {" "}
                Approve Request{" "}
              </DefaultButton>
            )}
            {item.status === "published" && (
              <DefaultButton
                styles={buttonStyles.warning}
                disabled={updating}
                onClick={onCancel}
              >
                {" "}
                Cancel Event{" "}
              </DefaultButton>
            )}
            {item.status === "pending" && (
              <DefaultButton
                styles={buttonStyles.danger}
                disabled={updating}
                onClick={openModal}
              >
                {" "}
                Reject Request{" "}
              </DefaultButton>
            )}
          </Flex>
        </Box>
      </Grid>
      <Dialog
        isOpen={isOpen}
        type={DialogType.close}
        onDismiss={() => setIsOpen(false)}
        title=""
        subText=""
        isBlocking
        closeButtonAriaLabel="Close"
        styles={appsDialogStyles.medium}
      >
        <Label sx={{ mb: 2 }}>
          Please enter the reason for rejection
          <span style={{ color: "red" }}>*</span>
        </Label>
        <Textarea
          value={rejectReason}
          rows={5}
          name="reject_reason"
          onChange={handleInputChange}
        />
        <DialogFooter>
          <DefaultButton
            styles={buttonStyles.blue}
            disabled={!rejectReason || updating}
            onClick={onReject}
          >
            {" "}
            Submit Rejection{" "}
          </DefaultButton>
          <DefaultButton
            styles={buttonStyles.default}
            onClick={() => {
              setRejectReason("");
              closeModal();
            }}
            disabled={updating}
          >
            {" "}
            Cancel{" "}
          </DefaultButton>
        </DialogFooter>
      </Dialog>
    </Container>
  );
}

ScheduleCardC.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  EditItemButton: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default function ScheduleCard({ item, EditItemButton, key, onSave }) {
  return (
    <ScheduleCardC
      item={item}
      EditItemButton={EditItemButton}
      key={key}
      onSave={onSave}
    />
  );
}

ScheduleCard.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  EditItemButton: PropTypes.objectOf(PropTypes.object).isRequired,
  key: PropTypes.objectOf(PropTypes.any).isRequired,
  onSave: PropTypes.objectOf(PropTypes.object).isRequired,
};
